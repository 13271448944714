import produce from "immer"
import React, {useEffect, useState} from "react"
import Loading from "components/loading/Loading"
import useAxiosPrivate from "hooks/useAxiosPrivate"
import {Featured, Product} from "utilities/Interfaces"
import ProductForm from "admin/components/product-form/ProductForm"
import AdminNavigation from "admin/components/admin-navigation/AdminNavigation"

import "./dashboard.css"

//TODO add confirmation message before deleting them
const Dashboard = () => {

    const axiosPrivate = useAxiosPrivate()

    const [loading, setLoading] = useState(true)
    const [allProducts, setAllProducts] = useState<Product[]>([])

    const [focusedProduct, setFocusedProduct] = useState<Product>()

    const [addingProduct, setAddingProduct] = useState(false)

    const [toRemove, setToRemove] = useState<number[]>([])
    const [removeProducts, setRemovingProducts] = useState(false)

    const [featuredIds, setFeaturedIds] = useState<number[]>([])
    const [managingFeatured, setManagingFeatured] = useState(false)

    useEffect(() => {
        axiosPrivate.get("/api/v1/products/get-all-sorted")
            .then(response => {
                setAllProducts(response.data)
                setLoading(false)
            })

    }, [focusedProduct, addingProduct, removeProducts, axiosPrivate])

    useEffect(() => {
        axiosPrivate.get("/api/v1/products/get-featured")
            .then(response => {
                let ids: number[] = []
                for (const item of response.data) {
                    ids.push(item.id)
                }
                setFeaturedIds(ids)
            })

    }, [managingFeatured, axiosPrivate])

    const confirmRemove = () => {
        axiosPrivate.delete(`/management/v1/products/delete-all/${toRemove}`)
            .then(() => {
                setToRemove([])
                setRemovingProducts(false)
            })
    }

    const handleSelectToRemove = (id: number) => {
        if (toRemove.includes(id)) setToRemove(prevState => prevState.filter(pid => pid !== id))
        else setToRemove(produce(toRemove, draft => {
            draft.push(id)
        }))
    }

    const handleSelectFeatured = (id: number) => {
        if (featuredIds.includes(id)) setFeaturedIds(prevState => prevState.filter(pid => pid !== id))
        else setFeaturedIds(produce(featuredIds, draft => {
            draft.push(id)
        }))
    }

    const convertFeaturedIdsToFeatured = () => {
        let temp: Featured[] = []
        for (let i = 0; i < featuredIds.length; i++) {
            let f: Featured = {
                id: 0,
                priority: i,
                product: featuredIds[i]
            }
            temp.push(f)
        }
        return temp
    }

    const confirmUpdateFeatured = () => {
        const newFeatured = convertFeaturedIdsToFeatured()
        axiosPrivate.put("/management/v1/featured/update", newFeatured)
            .then(() => cancelEverything())
    }

    const cancelEverything = () => {
        setAddingProduct(false)
        setRemovingProducts(false)
        setFocusedProduct(undefined)
        setManagingFeatured(false)
    }

    const CancelButton = () => {
        return (
            <button
                type="button"
                className="form-btn"
                onClick={cancelEverything}
            >
                Cancel
            </button>
        )
    }

    return (
        <>
            <AdminNavigation/>
            <div className="dashboard__container">
                <h1 onClick={cancelEverything}>Admin Dashboard</h1>
                <h2>What would you like to do?</h2>
                {
                    loading ?
                        <Loading/> :
                        <>
                            <div className="dashboard__options">
                                <button
                                    className="form-btn form-btn__add"
                                    onClick={() => {
                                        setAddingProduct(true)
                                        setRemovingProducts(false)
                                        setFocusedProduct(undefined)
                                        setManagingFeatured(false)
                                        window.scroll(0, 0)
                                    }}
                                >
                                    Add New Product
                                </button>
                                {
                                    allProducts.length > 0 &&
                                    <>
                                        <button
                                            className="form-btn form-btn__remove"
                                            onClick={() => {
                                                setAddingProduct(false)
                                                setRemovingProducts(true)
                                                setFocusedProduct(undefined)
                                                setManagingFeatured(false)
                                            }}
                                        >
                                            Remove Products
                                        </button>
                                        <button
                                            className="form-btn form-btn__yellow"
                                            onClick={() => {
                                                setAddingProduct(false)
                                                setRemovingProducts(false)
                                                setFocusedProduct(undefined)
                                                setManagingFeatured(true)
                                            }}
                                        >
                                            Manage Featured Products
                                        </button>
                                    </>
                                }
                            </div>
                            {
                                removeProducts &&
                                <div>
                                    <h3>Removing Products</h3>
                                    <div className="dashboard__buttons-row">
                                        <button
                                            onClick={toRemove.length > 0 ? confirmRemove : () => {
                                                setRemovingProducts(false)
                                            }}
                                            className="dashboard-btn__delete"
                                        >
                                            Confirm Deletion
                                        </button>
                                        <button
                                            onClick={() => {
                                                cancelEverything()
                                                setToRemove([])
                                            }}
                                            className="dashboard-btn__delete"
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            }
                            {
                                managingFeatured &&
                                <div>
                                    <h3>Managing Featured Products</h3>
                                    <div className="dashboard__buttons-row">
                                        <button
                                            type="button"
                                            onClick={featuredIds.length > 0 ? confirmUpdateFeatured : () => {
                                                setManagingFeatured(false)
                                            }}
                                            className="dashboard-btn__delete"
                                        >
                                            Confirm Update
                                        </button>
                                        <button
                                            onClick={() => {
                                                cancelEverything()
                                            }}
                                            className="dashboard-btn__delete"
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            }
                            {
                                focusedProduct ?
                                    <ProductForm
                                        initialProduct={focusedProduct}
                                        cancelButton={CancelButton}
                                        cancelEverything={cancelEverything}
                                    /> :
                                    addingProduct ?
                                        <ProductForm
                                            cancelButton={CancelButton}
                                            cancelEverything={cancelEverything}
                                        /> :
                                        removeProducts ?
                                            <div className="dashboard__product-list">
                                                {
                                                    allProducts.map((product: Product) => {
                                                        return (
                                                            <div key={product.id} className="dashboard__edit-item"
                                                                 onClick={() => handleSelectToRemove(product.id)}
                                                                 style={{
                                                                     backgroundColor: `${
                                                                         toRemove.includes(product.id as number)
                                                                             ? "rgba(137,224,255,0.62)"
                                                                             : "white"
                                                                     }`
                                                                 }}
                                                            >
                                                                {product.name}
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div> :
                                            managingFeatured ?
                                                <div className="dashboard__product-list">
                                                    {
                                                        allProducts.map((product: Product) => {
                                                            return (
                                                                <div key={product.id} className="dashboard__edit-item"
                                                                     onClick={() => handleSelectFeatured(product.id)}
                                                                     style={{
                                                                         backgroundColor: `${
                                                                             featuredIds.includes(product.id as number)
                                                                                 ? "rgba(137,224,255,0.62)"
                                                                                 : "white"
                                                                         }`
                                                                     }}
                                                                >
                                                                    {product.name}
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div> :
                                                allProducts.length > 0 ?
                                                    <>
                                                        <div className="dashboard__subtext">
                                                            <i>Or</i><br/> Select a product below to update it
                                                        </div>
                                                        <div className="dashboard__product-list">
                                                            {
                                                                allProducts.map((product: Product) => {
                                                                    return (
                                                                        <div key={product.id}
                                                                             className="dashboard__edit-item"
                                                                             onClick={() => {
                                                                                 setFocusedProduct(product)
                                                                                 window.scroll(0, 0)
                                                                             }}
                                                                        >
                                                                            {product.name}
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </> :
                                                    <div className="dashboard__error">
                                                        There are no products found in the database
                                                    </div>
                            }
                        </>
                }
            </div>
        </>
    )
}

export default Dashboard
import {useLocation} from "react-router-dom"

import "./footer.css"

const Footer = () => {
    const location = useLocation()

    return (
        <div hidden={location.pathname.includes("adminpanel")}>
            <div className="footer__bar">
                &copy; Semisweet. All Rights Reserved.
            </div>
        </div>
    )
}

export default Footer
import React, {useState} from "react"
import {FaSearch} from "react-icons/fa"
import {useLocation, useNavigate} from "react-router-dom"

import {BLANK} from "utilities/Constants"
import Directory from "utilities/Directory"

import "./search-bar.css"

const SearchBar = (props: {myID: string}) => {

    const navigate = useNavigate()
    const [searching, setSearching] = useState(BLANK)

    const onMenu = useLocation().pathname === Directory.MENU
    const makeSearchRequest = () => {
        navigate(`${Directory.MENU}?${searching.trim()}`)
        setSearching(BLANK)
    }

    return (
        <div className="search-container">
            <form
                onSubmit={(e) => {
                    e.preventDefault()
                    makeSearchRequest()
                }}>
                <input
                    id={props.myID} type="search" placeholder="Search"
                    disabled={onMenu}
                    className="search expandright"
                    value={searching} required
                    onChange={(e) => {
                        setSearching(e.target.value)
                    }}/>
                <label className="search__button searchbutton" htmlFor={props.myID}>
                    <FaSearch size={20}/>
                </label>
            </form>
        </div>
    )
}

export default SearchBar
import React from "react"
import {useNavigate} from "react-router-dom"

import Directory from "utilities/Directory"

import "./error.css"

const Error = () => {
    const navigate = useNavigate()

    return (
        <div className="error__container">
            <h1>404 Page Not Found</h1>
            <p className="error__text">The page you are looking for does not exist</p>
            <button className="error__button" onClick={() => navigate(Directory.MENU)}>
                Continue Shopping
            </button>
        </div>
    )
}

export default Error
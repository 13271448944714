import {useLocalStorage} from "usehooks-ts"

const useToggle = (key: string, initValue: boolean): [boolean, (value: any) => void] => {
    const [value, setValue] = useLocalStorage(key, initValue)

    const toggle = (value: any) =>
        setValue((prevState: any) => typeof value === "boolean" ? value : !prevState)

    return [value, toggle]
}

export default useToggle
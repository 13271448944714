import "./assets.css"

export const SemisweetLogo = (props: any) =>
    <img
        className="logo__image"
        src={require("assets/source/logo-nobg.png")}
        onClick={props.goToHome}
        alt="Logo"
    />

export const Spoon = () =>
    <img
        className="spoon__image"
        src={require("assets/source/spoon-hq.png")}
        alt="S'mores dripping off a spoon"
    />

export const Blobs = () =>
    <img
        className="blobs__image"
        src={require("assets/source/blobss-hq.png")}
        alt="Yellow blobs"
    />

//Ramadan Images

export const Clouds = () =>
    <img
        className="clouds__image"
        src={require("assets/source/ramadan/Clouds.png")}
        alt="Clouds"
    />

const LanternLeft = () =>
    <img
        className="lantern__image lantern__left"
        src={require("assets/source/ramadan/Lantern.png")}
        alt="Lantern Left Side"
    />

const LanternRight = () =>
    <img
        className="lantern__image lantern__right"
        src={require("assets/source/ramadan/Lantern.png")}
        alt="Lantern Right Side"
    />

const Moon = () =>
    <img
        className="moon__image"
        src={require("assets/source/ramadan/Moon.png")}
        alt="Moon"
    />

const Mosque = () =>
    <img
        className="mosque__image"
        src={require("assets/source/ramadan/Mosque.png")}
        alt="Mosque"
    />

export const Stars = () =>
    <img
        className="stars__image"
        src={require("assets/source/ramadan/Stars.png")}
        alt="Stars"
    />

export const RamadanImages = () =>
    <>
        <Clouds/>
        <LanternLeft/>
        <LanternRight/>
        <Moon/>
        <Mosque/>
        <Stars/>
    </>

export const EidTart = () =>
    <img
        className="eid-tart__image"
        src={require("assets/source/eid/EidTart.jpeg")}
        alt="Chocolate Tart which Eid Mubarak on it in Arabic"
    />

export const EidMoon = () =>
    <img
        className="moon__image-eid"
        src={require("assets/source/ramadan/Moon.png")}
        alt="Moon"
    />
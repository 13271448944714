import React from "react"
import {Helmet} from "react-helmet-async"

import Welcome from "./welcome/Welcome"
import VisitUs from "./visit-us/VisitUs"
import Directory from "utilities/Directory"
import {SEMISWEET_DESCRIPTION} from "utilities/Constants"
import FeaturedProducts from "./featured-products/FeaturedProducts"

import "./home.css"

const Home = () => {

    return (
        <>
            <Helmet>
                <title>Semisweet Homepage</title>
                <meta name="description"
                      content={SEMISWEET_DESCRIPTION}
                />
                <link rel="canonical" href={Directory.ROOT}/>
            </Helmet>

            <div>
                <div className="home__container">
                    <Welcome/>
                </div>
                <FeaturedProducts/>
                <VisitUs/>
            </div>
        </>
    )
}

export default Home
import useAuth from "hooks/useAuth"
import Directory from "utilities/Directory"
import {Navigate, Outlet, useLocation} from "react-router-dom"

const NavigateLoggedIn = () => {
    const {auth} = useAuth()
    const location = useLocation()

    return (
        auth.user && location.pathname === Directory.ADMIN_SIGN_IN
            ? <Navigate to={Directory.ADMIN_DASHBOARD} state={{from: location}} replace/>
            : <Outlet/>
    )
}

export default NavigateLoggedIn
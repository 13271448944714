import React, {useEffect, useState} from "react"
import {useLocation, useNavigate} from "react-router-dom"

import useSignOut from "hooks/useSignOut"
import Directory from "utilities/Directory"
import {SemisweetLogo} from "assets/assets"

import "./admin-navigation.css"

const AdminNavigation = () => {

    const signOut = useSignOut()
    const navigate = useNavigate()
    const location = useLocation()
    const [navbar, setNavbar] = useState(false)

    const changeBackground = () => {
        if (window.scrollY >= 50) setNavbar(true)
        else setNavbar(false)
    }

    useEffect(() => {
        window.addEventListener("scroll", changeBackground)

        return () => { window.removeEventListener("scroll", changeBackground) }
    }, [])

    if (!location.pathname.includes("adminpanel")) return <></>

    const handleSignOut = async (mouseEvent: React.MouseEvent<any>) => {
        mouseEvent.preventDefault()
        await signOut()
        navigate(Directory.ROOT)
    }

    return (
        <div
            className={(navbar || location.pathname !== Directory.ROOT)
                ? "admin-navigation__bar admin-navigation__sticky"
                : "admin-navigation__bar"}
        >
            <SemisweetLogo goToHome={() => navigate(Directory.ROOT)}/>
            <div onClick={handleSignOut} className="sign-out__button">
                Sign Out
            </div>
        </div>
    )
}

export default AdminNavigation
export const EMAIL_ADDRESS = "info@semisweet.ae"
export const WHATSAPP_LINK = "https://wa.me/971565379191"
export const TIKTOK_URL = "https://www.tiktok.com/@semisweet.ae?lang=en"
export const INSTAGRAM_URL = "https://www.instagram.com/semisweet.ae/?hl=en"
export const AL_SAFA_MAPS = "https://goo.gl/maps/P6arKJg82akemMDf7"
export const OXYGEN_PADEL_MAPS = "https://goo.gl/maps/FZWZUGDNsvyTsmhU6"
export const BUCKET_ASSETS_LINK = "https://elasticbeanstalk-me-south-1-185988141673.s3.me-south-1.amazonaws.com/resources/website-assets/"
export const SEMISWEET_DESCRIPTION = "Sharing Sweet Memories Since 2019. We deliver your favourite desserts and coffees all over the UAE."
export const ABOUT_BLURB = "Semisweet is a homegrown dessert shop based in the UAE since 2019. It is known for its " +
    "delicious S‘mores and many other desserts such as Cookies, Brownies, Banoffee, Pudding and more. " +
    "Semisweet delivers to your doorstep, caters at, and participates in events."

export const CATEGORIES = [
    "All",
    "Cake",
    "Brownies",
    "Cookies",
    "Pudding",
    "Truffles",
    "Coffee",
    "Miscellaneous"
]

export const BLANK = ""
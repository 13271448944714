import produce from "immer"
import Select from "react-select"
import React, {useState} from "react"
import HttpStatus from "http-status-codes"

import {categories} from "utilities/Categories"
import {allergenList} from "utilities/Allergens"
import useAxiosPrivate from "hooks/useAxiosPrivate"
import {BLANK, BUCKET_ASSETS_LINK} from "utilities/Constants"
import ImageUpload from "admin/components/image-upload/ImageUpload"
import {convertToProduct, convertToProductStruct} from "utilities/Functions"
import {defaultPrice, defaultProductStruct, Price, Product, ProductStruct} from "utilities/Interfaces"

import "./product-form.css"

const ProductForm = (props: { cancelButton: any, cancelEverything: any, initialProduct?: Product }) => {
    const axiosPrivate = useAxiosPrivate()

    const CancelButton = props.cancelButton
    const cancelEverything = props.cancelEverything
    const initialProduct = props.initialProduct

    const [response, setResponse] = useState<string>(BLANK)
    const [disabledButtons, setDisabledButtons] = useState(false)

    const [primaryImageLink, setPrimaryImageLink] = useState<string>()
    const [uploadedFiles, setUploadedFiles] = useState<File[]>([])

    const [selectedImages, setSelectedImages] = useState<string[]>([])
    const [focusedImage, setFocusedImage] = useState(-1)


    // If the initial product is empty then set to default Product Struct otherwise convert to struct
    const [editedProduct, setEditedProduct] =
        useState<ProductStruct>(
            initialProduct ? convertToProductStruct(initialProduct) : defaultProductStruct()
        )

    // New Product Functions
    const addNewProduct = async (event: React.FormEvent) => {
        event.preventDefault()

        setDisabledButtons(false)
        setResponse("Processing...")

        let productId = 0
        let productStatus = undefined

        const product: Product = convertToProduct(editedProduct)

        await axiosPrivate.post("/management/v1/products/add", product)
            .then(response => {
                setTimeout(() => {
                    setResponse(`The product "${product.name}" has been successfully added`)
                }, 1000)
                setTimeout(() => {
                    cancelEverything()
                }, 3000)
                productId = response.data
                productStatus = response.status
            })
            .catch(error => {
                setResponse("The product was not added, an error occurred with code: " + error.response.status)
            })

        if (productStatus === HttpStatus.CREATED) {
            uploadedFiles.forEach(file => {
                const formData = new FormData()
                formData.append("image", file)
                if (file.name === primaryImageLink) formData.append("primary", JSON.stringify(true))

                axiosPrivate.put(`/management/v1/products/upload-image/${productId}`, formData, {headers: {"content-Type": "multipart/form-data"}})
                    .then(() => {})
            })
        }
    }
    // Edit Product Functions
    const handleDeleteProduct = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault()
        if (initialProduct)
            axiosPrivate.delete(`/management/v1/products/delete/${initialProduct.id}`)
                .then(() => cancelEverything())
    }

    const handleProductUpdate = async (event: React.FormEvent) => {
        event.preventDefault()

        if (!initialProduct) return

        let uploadedPrimary: boolean = false
        const product: Product = convertToProduct(editedProduct)

        let errorsMade: any[] = []

        setResponse("Processing...")
        setDisabledButtons(true)

        await axiosPrivate.put("/management/v1/products/update-information", product)
            .catch(error => {
                setResponse("The product information was not updated.")
                errorsMade.push(error.response.status)
                setDisabledButtons(false)
            })

        await axiosPrivate.put(`/management/v1/prices/update/${product.id}`, product.prices)
            .catch(error => {
                setResponse("The prices were not updated.")
                errorsMade.push(error.response.status)
                setDisabledButtons(false)
            })

        for (const file of uploadedFiles) {

            const formData = new FormData()
            formData.append("image", file)
            if (primaryImageLink === file.name) {
                formData.append("primary", JSON.stringify(true))
                uploadedPrimary = true
            }

            await axiosPrivate
                .put(`/management/v1/products/upload-image/${product.id}`,
                    formData,
                    {headers: {"content-Type": "multipart/form-data"}}
                )
                .catch(error => {
                    setResponse("The file " + file.name + " could not be uploaded")
                    errorsMade.push(error.response.status)
                    setDisabledButtons(false)
                })
        }

        for (const link of selectedImages) {
            await axiosPrivate.delete(`/management/v1/products/remove-product-link/${initialProduct.id}/${link}`)
                .catch(error => {
                    setResponse("Could not delete: " + link)
                    errorsMade.push(error.response.status)
                    setDisabledButtons(false)
                })
        }

        if (primaryImageLink && !uploadedPrimary) {
            // Update the primary image
            await axiosPrivate.put(`/management/v1/products/update-primary/${initialProduct.id}/${primaryImageLink}`)
                .catch(error => {
                    setResponse("The primary image could not be updated.")
                    errorsMade.push(error.response.status)
                    setDisabledButtons(false)
                })
        }

        if (errorsMade.length === 0) {
            setResponse("Product Information was updated successfully")
            setTimeout(() => {
                cancelEverything()
            }, 2000)
        }
    }

    const handleImageSelect = (link: string) => {
        setSelectedImages(produce(selectedImages, draft => { draft.push(link) }))
        setFocusedImage(-1)
    }

    const handleImageDeselect = (link: string) => {
        setSelectedImages(prevState => {return prevState.filter(plink => plink !== link)})
        setFocusedImage(-1)
    }

    const handlePriceRemove = (index: number) => {
        setEditedProduct(produce(editedProduct, draft => { draft.prices.splice(index, 1) }))
    }

    const handlePriceAdd = () => {
        setEditedProduct(produce(editedProduct, draft => { draft.prices.push(defaultPrice()) }))
    }

    const handleCostChange = (changeEvent: React.ChangeEvent<HTMLInputElement>, index: number) => {
        setEditedProduct(produce(editedProduct, draft => { draft.prices[index].cost = parseFloat(changeEvent.target.value) }))
    }

    const handleServingSizeChange = (changeEvent: React.ChangeEvent<HTMLInputElement>, index: number) => {
        setEditedProduct(produce(editedProduct, draft => { draft.prices[index].servingSize = changeEvent.target.value }))
    }

    const removeFromUploads = (index: number) => {
        setUploadedFiles(produce(uploadedFiles, draft => { draft.splice(index, 1) }))
    }

    const handleSetAsPrimary = (link: string) => {
        setPrimaryImageLink(link)
        handleImageDeselect(link)
    }

    const convertBytesToMegaBytes = (bytes: number) => {
        let megabytes = bytes / 1000000
        if (megabytes < 1) return megabytes.toPrecision(2)
        else return megabytes.toPrecision(4)
    }

    return (
        <div>
            <div className="form__header">
                <button
                    type="button"
                    className="form-btn__back"
                    onClick={cancelEverything}
                >
                    Back
                </button>
                {
                    initialProduct ?
                        <h2>Currently Viewing <u>{initialProduct.name}</u></h2> :
                        <h2>Adding New Product</h2>
                }
                <button
                    type="button"
                    className="form-btn__back"
                    onClick={handleDeleteProduct}
                >
                    Delete
                </button>
            </div>
            <div className="form__header-small">
                {
                    initialProduct ?
                        <h2>Currently Viewing <u>{initialProduct.name}</u></h2> :
                        <h2>Adding New Product</h2>
                }
                <div>
                    <button
                        type="button"
                        className="form-btn__back"
                        onClick={cancelEverything}
                    >
                        Back
                    </button>
                    <button
                        type="button"
                        className="form-btn__back"
                        onClick={handleDeleteProduct}
                    >
                        Delete
                    </button>
                </div>
            </div>
            <form className="form__container" onSubmit={initialProduct ? handleProductUpdate : addNewProduct}>
                <div className="form__row">
                    <div className="form__component">
                        <label>
                            Product Name
                        </label>
                        <input
                            placeholder="Product Name"
                            type="text"
                            value={editedProduct.name}
                            onChange={event => {setEditedProduct(produce(editedProduct, draft => {draft.name = event.target.value}))}}
                            required
                        />
                    </div>
                    <div className="form__component">
                        <label>Description</label>
                        <textarea
                            placeholder="Product Description"
                            value={editedProduct.description || ""}
                            onChange={event => setEditedProduct(produce(editedProduct, draft => {draft.description = event.target.value}))}
                        />
                    </div>
                </div>
                <div className="form__row">
                    <div className="form__component">
                        <label>Category (Select One)</label>
                        <Select
                            name="category"
                            value={editedProduct.category}
                            onChange={event => setEditedProduct(produce(editedProduct, draft => {draft.category = event}))}
                            options={categories}
                        />
                    </div>
                    <div className="form__component">
                        <label>Allergens (Select All that Apply)</label>
                        <Select
                            isMulti
                            name="allergens"
                            value={editedProduct.allergens}
                            options={allergenList}
                            closeMenuOnSelect={false}
                            onChange={event => setEditedProduct(produce(editedProduct, draft => {draft.allergens = event}))}
                        />
                    </div>
                </div>
                <h3>Adding Prices</h3>
                <div>
                    {
                        editedProduct.prices.map((element: Price, index: number) => {
                            return (
                                <div key={index} className="form__row">
                                    <div className="form__component">
                                        <label>Cost in AED</label>
                                        <input
                                            type="number"
                                            placeholder="Cost in AED"
                                            required
                                            value={element.cost}
                                            onChange={e => handleCostChange(e, index)}
                                        />
                                    </div>
                                    <div className="form__component">
                                        <label>Serving Size</label>
                                        <input
                                            placeholder="Serving Size"
                                            value={element.servingSize || ""}
                                            onChange={e => handleServingSizeChange(e, index)}
                                        />
                                    </div>
                                    <div className="form__component" style={{flex: "0.5"}}>
                                        <label
                                            className="form-price__remove"
                                            onClick={() => handlePriceRemove(index)}
                                        >
                                            Remove Price
                                        </label>
                                    </div>

                                </div>
                            )
                        })
                    }
                    <button type="button" onClick={handlePriceAdd} className="form-btn form-btn__secondary">
                        Add Another Price
                    </button>
                </div>
                <h3>Edit Product Images</h3>
                <div className="form__images-grid">
                    {
                        editedProduct.productLinks.length > 0 ?
                            editedProduct.productLinks.map((link: string, index: number) => {
                                return (
                                    index === focusedImage ?
                                        <div key={index} className="form__image">
                                            <img
                                                key={index}
                                                src={BUCKET_ASSETS_LINK + link}
                                                alt={editedProduct.name}
                                                onClick={() => setFocusedImage(index)}
                                            />
                                            <div className="form__image-options">
                                                <>
                                                    {
                                                        editedProduct.primaryImage !== link &&
                                                        <button
                                                            type="button"
                                                            className="form-btn__tertiary"
                                                            onClick={() => { handleSetAsPrimary(link)}}
                                                        >
                                                            Set as primary
                                                        </button>
                                                    }
                                                    {
                                                        selectedImages.includes(link) ?
                                                            <button
                                                                type="button"
                                                                className="form-btn__tertiary"
                                                                onClick={() => handleImageDeselect(link)}
                                                            >
                                                                Remove from Deletion
                                                            </button> :
                                                            <button
                                                                type="button"
                                                                className="form-btn__tertiary"
                                                                onClick={() => handleImageSelect(link)}
                                                            >
                                                                Delete Image
                                                            </button>
                                                    }
                                                </>
                                                <button
                                                    type="button"
                                                    className="form-btn__tertiary"
                                                    onClick={() => setFocusedImage(-1)}
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                        </div> :
                                        <div key={index} className="form__image">
                                            <img
                                                key={index}
                                                src={BUCKET_ASSETS_LINK + link}
                                                alt={editedProduct.name}
                                                onClick={() => setFocusedImage(index)}
                                            />
                                            <div>
                                                {
                                                    editedProduct.primaryImage === link && "Current Primary Image"
                                                }
                                            </div>
                                            <div>
                                                {
                                                    selectedImages.includes(link) && "Marked for Deletion"
                                                }
                                            </div>
                                            <div>
                                                {
                                                    primaryImageLink === link && "Selected to be Primary Image"
                                                }
                                            </div>
                                        </div>
                                )
                            }) :
                            <div>
                                There are no images uploaded for this product
                            </div>
                    }
                </div>
                <div className="form__image-upload">
                    <ImageUpload
                        setResponse={setResponse}
                        uploadedFiles={uploadedFiles}
                        setUploadedFiles={setUploadedFiles}
                    />
                </div>
                <div>
                    {
                        uploadedFiles.length > 0 ?
                            <>
                                Currently Uploaded Files:
                                {
                                    uploadedFiles.map((file: File, index: number) => {
                                        return (
                                            <div key={index} className="form__upload-row">
                                                {file.name + " | Size: " + convertBytesToMegaBytes(file.size) + "MB"}
                                                {
                                                    primaryImageLink === file.name ?
                                                        <button
                                                            type="button"
                                                            className="form-btn__tertiary"
                                                            onClick={() => setPrimaryImageLink(undefined)}
                                                        >
                                                            Marked as Primary<br/>(Click to Deselect)
                                                        </button> :
                                                        <button
                                                            type="button"
                                                            className="form-btn__tertiary"
                                                            onClick={() => setPrimaryImageLink(file.name)}
                                                        >
                                                            Mark as Primary
                                                        </button>
                                                }
                                                <button
                                                    type="button"
                                                    className="form-btn__tertiary"
                                                    onClick={() => removeFromUploads(index)}
                                                >
                                                    Remove from Uploads
                                                </button>
                                            </div>
                                        )
                                    })
                                }
                            </> :
                            "You haven't uploaded any files yet"
                    }
                </div>

                <div className="form__row">
                    <button type="submit" className="form-btn" disabled={disabledButtons}>
                        Submit
                    </button>
                    <CancelButton/>
                    {
                        initialProduct &&
                        <button type="button" onClick={handleDeleteProduct} className="form-btn">
                            Delete <u>{initialProduct.name}</u>
                        </button>
                    }
                </div>
                <p className="form__response">{response}</p>
            </form>
        </div>
    )
}

export default ProductForm
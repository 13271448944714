import {FaTiktok} from "react-icons/fa"
import {BsInstagram} from "react-icons/bs"
import React, {useEffect, useState} from "react"
import {useLocation, useNavigate} from "react-router-dom"
import {AiOutlineClose, AiOutlineMenu} from "react-icons/ai"

import Directory from "utilities/Directory"
import {SemisweetLogo} from "assets/assets"
import SearchBar from "components/search-bar/SearchBar"
import {INSTAGRAM_URL, TIKTOK_URL} from "utilities/Constants"

import "./navigation.css"

interface Setting {
    "directory": Directory
    "name": string
}

const settings = [
    {
        "directory": Directory.ROOT,
        "name": "Home"
    },
    {
        "directory": Directory.MENU,
        "name": "Menu"
    },
    {
        "directory": Directory.CONTACT_US,
        "name": "Contact Us"
    }
]

// TODO WHEN THERE IS ONE SLIDER ON HOME PAGE HAVE THIS BE FALSE BUT THERE IS 2 MAKE NAVBAR TRUE
const Navigation = () => {
    const location = useLocation()
    const navigate = useNavigate()

    const [navbar, setNavbar] = useState(false)
    const [sidebar, setSidebar] = useState(false)

    const toggleSidebar = () => setSidebar(prev => !prev)

    const changeBackground = () => {
        if (window.scrollY >= 50) setNavbar(true)
        else setNavbar(false)
    }

    useEffect(() => {
        window.addEventListener("scroll", changeBackground)

        return () => { window.removeEventListener("scroll", changeBackground) }
    }, [])

    if (location.pathname.includes("adminpanel")) return <></>

    return (
        <>
            <div
                className={(navbar || location.pathname !== Directory.ROOT)
                    ? "navigation__bar navigation__sticky"
                    : "navigation__bar"}
            >
                <SemisweetLogo goToHome={() => navigate(Directory.ROOT)}/>
                <div className="navigation__settings">
                    {
                        settings.map((setting: Setting, index: number) => {
                            return (
                                <div key={index}
                                     onClick={() => {
                                         navigate(setting.directory)
                                         window.scroll(0, 0)
                                     }
                                     }>
                                    <p>
                                        {setting.name}
                                    </p>
                                </div>
                            )
                        })
                    }
                </div>

                <div className="navigation__socials navigation__social-spacing">
                    <a
                        href={INSTAGRAM_URL}
                        target="_blank"
                        rel="noreferrer">
                        <BsInstagram size={20}/>
                    </a>
                    <a
                        href={TIKTOK_URL}
                        target="_blank"
                        rel="noreferrer">
                        <FaTiktok size={20}/>
                    </a>
                    <SearchBar myID={"searchrightbig"}/>
                </div>

                <div className="navigation__small-bar">
                    <SearchBar myID={"searchrightsmall"}/>
                    <AiOutlineMenu className="navigation__menu" onClick={toggleSidebar}/>
                </div>

            </div>
            {/*Sidebar Component*/}
            <div className={sidebar ? "navigation__sidebar active" : "navigation__sidebar"}>
                <div className="navigation__sidebar-settings">
                    <AiOutlineClose onClick={toggleSidebar} className="navigation__sidebar-close"/>
                    {
                        settings.map((setting: Setting, index: number) => {
                            return (
                                <div
                                    key={index}
                                    className="navigation__sidebar-settings__item"
                                    onClick={() => {
                                        navigate(setting.directory)
                                        toggleSidebar()
                                    }}>
                                    {setting.name}
                                </div>
                            )
                        })
                    }
                    <div className="navigation__sidebar-socials">
                        <a
                            href={INSTAGRAM_URL}
                            target="_blank"
                            rel="noreferrer">
                            <BsInstagram/>
                        </a>
                        <a
                            href={TIKTOK_URL}
                            target="_blank"
                            rel="noreferrer">
                            <FaTiktok/>
                        </a>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Navigation
import Allergens from "utilities/Allergens"
import Categories from "utilities/Categories"
import {Price, Product, ProductStruct} from "utilities/Interfaces"

export const convertToProduct = (productStruct: ProductStruct): Product => {
    return {
        id: productStruct.id,
        name: productStruct.name.trim(),
        description: productStruct.description === "" ? null : productStruct.description,
        allergens: productStruct.allergens.map((allergen: any) => {return allergen.value}),
        category: (productStruct.category === null || productStruct.category === undefined) ? Categories.MISCELLANEOUS : productStruct.category.value,
        prices: productStruct.prices.filter((price: Price) => price.cost > 0),
        primaryImage: productStruct.primaryImage,
        productLinks: productStruct.productLinks
    }
}

export const convertToProductStruct = (product: Product): ProductStruct => {
    return {
        id: product.id,
        name: product.name,
        description: product.description,
        allergens: product.allergens.map((allergen: Allergens) => {
            return {
                value: allergen,
                label: allergen.toString()
            }
        }),
        category: {
            value: product.category,
            label: product.category === null ? "" : product.category.toString()
        },
        prices: product.prices,
        primaryImage: product.primaryImage,
        productLinks: product.productLinks
    }
}

export const findLowestPrice = (productPrices: Price[]): Price => {
    let lowestPrice = productPrices[0]
    productPrices.forEach(item => {if (item.cost < lowestPrice.cost) lowestPrice = item})
    return lowestPrice
}
import {useEffect} from "react"

import useAuth from "hooks/useAuth"
import {axiosPrivate} from "api/axios"
import HttpStatus from "http-status-codes"
import useRefreshToken from "hooks/useRefreshToken"

const useAxiosPrivate = () => {
    const refresh = useRefreshToken()
    const {auth} = useAuth()

    useEffect(() => {

        //Intercepts requests to the server, checks that they contain the bearer token and if they do not, it is added
        const requestIntercept = axiosPrivate.interceptors.request.use(
            config => {
                if (config.headers !== undefined && !config.headers["Authorization"])
                    config.headers["Authorization"] = `Bearer ${auth?.accessToken}`
                return config

            }, error => Promise.reject(error)
        )

        /**
         * Intercepts responses from the server. If the response is good, it is returned without
         * intervention. Otherwise, the refresh method is called to get the new access token, and the previous request
         * retried with the new access token
         */
        const responseIntercept = axiosPrivate.interceptors.response.use(
            response => response, //if the response is good, it is returned
            async error => {
                const prevRequest = error?.config //get previous request

                //error is forbidden
                if (error?.response?.status === HttpStatus.FORBIDDEN && !prevRequest?.sent) {
                    prevRequest.sent = true //makes sure that this strategy is employed once
                    const newAccessToken = await refresh() //get new access token

                    if (newAccessToken) {
                        prevRequest.headers["Authorization"] = `Bearer ${newAccessToken}` //add the new access token to the headers
                        return axiosPrivate(prevRequest) //send the request again

                    }
                    else return Promise.reject(error)
                }
                return Promise.reject(error) //response failed for different reason
            }
        )

        //removing the interceptors from future axios requests lest they pile up
        return () => {
            axiosPrivate.interceptors.request.eject(requestIntercept)
            axiosPrivate.interceptors.response.eject(responseIntercept)
        }
    }, [auth, refresh])

    return axiosPrivate
}

export default useAxiosPrivate
import React from "react"
import {Navigate, Outlet, useLocation} from "react-router-dom"

import useAuth from "hooks/useAuth"
import Directory from "utilities/Directory"

const RequireAuth = () => {
    const {auth} = useAuth()
    const location = useLocation()

    return (
        auth.user
            ? <Outlet/>
            : location.pathname === Directory.ADMIN_DASHBOARD
                ? <Navigate to={Directory.ADMIN_SIGN_IN} state={{from: location}} replace/>
                : <Navigate to={Directory.ROOT} state={{from: location}} replace/>
    )
}

export default RequireAuth
import React from "react"
import {Outlet, Route, Routes} from "react-router-dom"

import Home from "pages/home/Home"
import Menu from "pages/menu/Menu"
import Error from "pages/error/Error"
import Directory from "utilities/Directory"
import Footer from "components/footer/Footer"
import RequireAuth from "security/RequireAuth"
import SignIn from "admin/pages/sign-in/SignIn"
import PersistLogin from "security/PersistLogin"
import ContactUs from "pages/contact-us/ContactUs"
import Dashboard from "admin/pages/dashboard/Dashboard"
import NavigateLoggedIn from "security/NavigateLoggedIn"
import Navigation from "components/navigation/Navigation"
import Unauthorized from "pages/unauthorized/Unauthorized"
import SingleProduct from "pages/single-product/SingleProduct"

const App = () => (
    <>
        <Navigation/>
        <Routes>
            <Route path={Directory.ROOT} element={<Outlet/>}>
                {/*Public Pages*/}
                <Route path={Directory.ROOT} element={<Home/>}/>
                <Route path={Directory.MENU} element={<Menu/>}/>
                <Route path={`${Directory.MENU}/:name`} element={<SingleProduct/>}/>
                <Route path={Directory.CONTACT_US} element={<ContactUs/>}/>

                {/*Log in Page*/}
                <Route element={<NavigateLoggedIn/>}>
                    <Route path={Directory.ADMIN_SIGN_IN} element={<SignIn/>}/>
                </Route>

                {/*Must be authenticated pages*/}
                <Route element={<PersistLogin/>}>
                    <Route element={<RequireAuth/>}>
                        <Route path={Directory.ADMIN_DASHBOARD} element={<Dashboard/>}/>
                    </Route>
                </Route>

                {/*Error Pages*/}
                <Route path={Directory.UNAUTHORIZED} element={<Unauthorized/>}/>
                <Route path={Directory.ERROR} element={<Error/>}/>
            </Route>
        </Routes>
        <Footer/>
    </>
)

export default App

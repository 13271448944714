import "swiper/css"
import axios from "axios"
import "swiper/css/pagination"
import "swiper/css/navigation"
import {Helmet} from "react-helmet-async"
import {Swiper, SwiperSlide} from "swiper/react"
import React, {useEffect, useState} from "react"
import {Autoplay, Navigation, Pagination} from "swiper"
import {useLocation, useParams} from "react-router-dom"

import Allergens from "utilities/Allergens"
import Directory from "utilities/Directory"
import {Price, Product} from "utilities/Interfaces"
import {BUCKET_ASSETS_LINK} from "utilities/Constants"

import "./single-product.css"

const SingleProduct = () => {
    const urlName = useParams().name
    const [product, setProduct] = useState<Product>(useLocation().state as Product)
    const [loading, setLoading] = useState(!product)

    useEffect(() => {
        const fetchData = async () => {
            const fetchedProduct = await axios.get(`/api/v1/products/get-by-name/${urlName}`)
                .then(response => { return response.data })
            setProduct(fetchedProduct)
        }

        if (product === null || product.name !== urlName) {
            fetchData()
                .then(() => setLoading(false))
        }

    }, [product, urlName])

    return (
        <>
            <Helmet>
                <title>{product.name ? product.name : ""}</title>
                {/*TODO will need a description here if the product doesn't have oen*/}
                <meta name="description"
                      content={product.description ? product.description : ""}
                />
                <link rel="canonical" href={`${Directory.MENU}/${product.name}`}/>
            </Helmet>

            <div className="product__section">
                {
                    loading ?
                        // TODO add loading thing here?
                    <h1>LOADING</h1> :
                        <>
                            <div className="product__images">
                                <Swiper
                                    spaceBetween={30}
                                    centeredSlides={true}
                                    autoplay={{
                                        delay: 3500,
                                        disableOnInteraction: false
                                    }}
                                    loop={true}
                                    pagination={{clickable: true}}
                                    navigation={true}
                                    modules={[Autoplay, Pagination, Navigation]}
                                    className="product__swiper"
                                >
                                    {
                                        product.productLinks.length > 1 ?
                                            product.productLinks.map((productLink: string, index: number) => {
                                                return (
                                                    <SwiperSlide key={index}>
                                                        <img
                                                            className="product__image-selected"
                                                            src={BUCKET_ASSETS_LINK + productLink}
                                                            alt={product.name}
                                                        />
                                                    </SwiperSlide>
                                                )
                                            }) :
                                            <img
                                                className="product__image-selected"
                                                src={BUCKET_ASSETS_LINK + product.productLinks[0]}
                                                alt={product.name}
                                            />
                                    }
                                </Swiper>
                            </div>
                            <div className="product__information">
                                <div className="product__info-area">
                                    <h1 className="product__info-name">{product.name}</h1>
                                    {
                                        product.prices.map((price: Price, index: number) => {
                                            return (
                                                <div key={index} className="product__info-price">
                                                    {price.cost} AED {price.servingSize && (`for ${price.servingSize}`)}
                                                </div>
                                            )
                                        })
                                    }
                                    <div className="product__details">
                                        <div className="product__info-description">
                                            {
                                                product.description && product.description !== "" ?
                                                    <>
                                                        <h4>Description</h4>
                                                        <p>
                                                            {product.description}
                                                        </p>
                                                    </> :
                                                    <></>
                                            }
                                        </div>
                                        {
                                            product.allergens.length > 0 &&
                                            <div className="product__info-allergens">
                                                Contains:&nbsp;
                                                {
                                                    product.allergens.map((allergen: Allergens) => {
                                                        return allergen + " "
                                                    })
                                                }
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </>
                }
            </div>
        </>
    )
}

export default SingleProduct
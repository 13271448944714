import React from "react"

import {AL_SAFA_MAPS, OXYGEN_PADEL_MAPS} from "utilities/Constants"

import "./visit-us.css"

const VisitUs = () => {
    return (
        <div className="visit__section">
            <h2>Our Locations</h2>

            <div className="visit__row">

                {/*Location 1*/}
                <div className="visit__location">
                    <div className="visit__information">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14442.797390771773!2d55.241915!3d25.1796277!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f69644654c337%3A0xba83f752feda5cab!2sSemisweet!5e0!3m2!1sen!2sae!4v1685016071109!5m2!1sen!2sae"
                            allowFullScreen={false} loading="lazy"
                            className="visit__map"
                            referrerPolicy="no-referrer-when-downgrade"
                        />
                        Al Safa 1, Al Safa Park Complex, Shop No. 3

                        <div className="visit__link">
                            <a href={AL_SAFA_MAPS} target={"_blank"}>
                                View on Map
                            </a>
                        </div>
                    </div>
                </div>

                {/*Location 2*/}
                <div className="visit__location">
                    <div className="visit__information">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3612.4621471481623!2d55.235050376031424!3d25.1200619348194!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f69dd41984d41%3A0x482a31132a70a1b8!2sOxygen%20Padel%20Sport%20Academy!5e0!3m2!1sen!2sae!4v1685016475044!5m2!1sen!2sae"
                            allowFullScreen={true} loading="lazy"
                            className="visit__map"
                            referrerPolicy="no-referrer-when-downgrade"
                        />

                        Al Quoz, Al Quoz Industrial Area 4, Oxygen Padel

                        <div className="visit__link">
                            <a href={OXYGEN_PADEL_MAPS} target={"_blank"}>
                                View on Map
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VisitUs
enum Directory {

    ROOT = "/",

    MENU = "/menu",

    SEARCHED = "/searched",

    ABOUT_US = "/about-us",

    CONTACT_US = "/contact-us",

    ADMIN_SIGN_IN = "/adminpanel",

    ADMIN_DASHBOARD = "/adminpanel/dashboard",

    ADMIN_REGISTER = "/adminpanel/register-users",

    UNAUTHORIZED = "/unauthorized",

    ERROR = "*"
}

export default Directory

import axios from "axios"
import React, {useState} from "react"
import {Helmet} from "react-helmet-async"
import {useLocation} from "react-router-dom"

import Directory from "utilities/Directory"
import {EMAIL_ADDRESS, WHATSAPP_LINK} from "utilities/Constants"

import "./contact-us.css"

const ContactUs = () => {

    const location = useLocation()
    const emailSubmission = new FormData()

    const [name, setName] = useState("")
    const [fromEmail, setFromEmail] = useState("")
    const [subject, setSubject] = useState("")
    const [body, setBody] = useState("")
    const [confirmationHidden, setConfirmationHidden] = useState(true)

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault()

        emailSubmission.append("name", name)
        emailSubmission.append("from", fromEmail)
        emailSubmission.append("subject", subject)
        emailSubmission.append("body", body)

        console.log(emailSubmission)

        axios.post(
            "/api/v1/emails/send-email",
            emailSubmission,
            {headers: {"Content-Type": "multipart/form-data"}})
            .then(response => {
                setConfirmationHidden(false)
                setTimeout(() => {
                    setConfirmationHidden(true)
                }, 2000)
                console.log(response)
            })
    }

    return (
        <>
            <Helmet>
                <title>Contact Us</title>
                <meta name="description"
                      content="If you have any inquireies, special requests, or comments, feel free to contact us."
                />
                <link rel="canonical" href={Directory.CONTACT_US}/>
            </Helmet>

            <div className={location.pathname === Directory.ROOT ? "home-contact-us__section" : "contact-us__section"}>
                <h1 className="contact-us__heading">Contact Us</h1>
                <div className="contact-us__information">
                    <p>For inquires, special requests, or comments, contact us below.</p>
                    <span>Safa Park Complex, Al Safa 1, Dubai, UAE</span>
                    <a href={`mailto:${EMAIL_ADDRESS}`} target="_blank" rel="noreferrer">{EMAIL_ADDRESS}</a>
                    <a href={WHATSAPP_LINK} target="_blank" rel="noreferrer">+971 56 537 9191</a>
                </div>

                <form onSubmit={formEvent => handleSubmit(formEvent)} className="contact-us__form">

                    <div className="contact-us__form__name">
                        <p className="contact-us__labels">Name</p>
                        <input
                            className="contact-us__form__input"
                            placeholder="Your Name"
                            value={name}
                            onChange={event => setName(event.target.value)}
                            required
                        />
                    </div>

                    <div className="contact-us__form__email">
                        <p className="contact-us__labels">
                            Email Address
                        </p>
                        <input
                            className="contact-us__form__input"
                            placeholder="Your Email"
                            value={fromEmail}
                            type="email"
                            onChange={event => setFromEmail(event.target.value)}
                            required
                        />
                    </div>

                    <div className="contact-us__form__email">
                        <p className="contact-us__labels">
                            Subject
                        </p>
                        <input
                            className="contact-us__form__input"
                            placeholder="Subject of Inquiry"
                            value={subject}
                            onChange={event => setSubject(event.target.value)}
                            required
                        />
                    </div>

                    <div className="contact-us__form__message">
                        <p className="contact-us__labels">
                            Message
                        </p>
                        <textarea
                            className="contact-us__form__input"
                            placeholder="Write your message here"
                            value={body}
                            onChange={event => setBody(event.target.value)}
                            required
                        />
                    </div>

                    <button className="trademark-btn contact__submit-btn" type="submit">
                        Submit Form
                    </button>
                </form>
                <div
                    id="emailSentConfirmation"
                    className="contact-us__confirmation"
                    hidden={confirmationHidden}
                >
                    Your message has been sent!
                </div>
            </div>
        </>
    )
}

export default ContactUs
import axios from "axios"
import {useLocation, useNavigate} from "react-router-dom"

import useAuth from "hooks/useAuth"
import Directory from "utilities/Directory"

const useRefreshToken = () => {
    const {setAuth} = useAuth()
    const navigate = useNavigate()
    const location = useLocation()

    return async () =>
        await axios.get("/refresh", {withCredentials: true})
            .then(res => {

                setAuth((prevState: any) => ({
                    ...prevState,
                    user: res.data.user,
                    accessToken: res.data.accessToken
                }))

                return res.data.accessToken

            }).catch(() => {
                navigate(Directory.ADMIN_SIGN_IN, {state: {from: location}, replace: true})
                return null
            })
}

export default useRefreshToken
import axios from "axios"
import {Helmet} from "react-helmet-async"
import React, {useEffect, useState} from "react"
import {Link, useLocation, useNavigate} from "react-router-dom"

import Directory from "utilities/Directory"
import {Product} from "utilities/Interfaces"
import Loading from "components/loading/Loading"
import {findLowestPrice} from "utilities/Functions"
import {BLANK, BUCKET_ASSETS_LINK, CATEGORIES} from "utilities/Constants"

import "./menu.css"

const Menu = () => {

    const location = useLocation()
    const navigate = useNavigate()
    const searchURL = location.search.split("?").pop() || BLANK

    const [search, setSearch] = useState(searchURL)
    const [loading, setLoading] = useState(true)
    const [allProducts, setAllProducts] = useState<Product[]>([])
    const [selectedCategory, setSelectedCategory] = useState(searchURL === BLANK ? "All" : BLANK)

    // TODO consider pagination where you get by category - not all
    useEffect(() => {
        window.scrollTo(0, 0)

        axios.get("/api/v1/products/get-all")
            .then(response => {
                setAllProducts(response.data)
                setLoading(false)
            })

    }, [])

    const filteredProducts = (): Product[] => {
        if (search !== BLANK) {
            return allProducts.filter(product => (product.name.toLowerCase().includes(search)))
        }

        if (selectedCategory === "All") return allProducts
        return allProducts.filter(product => (product.category === selectedCategory && product))
    }

    return (
        <>
            <Helmet>
                <title>Menu</title>
                <meta name="description"
                      content="Check out our menu where you can find all the finest desserts and coffee."
                />
                <link rel="canonical" href={Directory.MENU}/>
            </Helmet>
            {
                loading ?
                    <Loading/> :
                    <div className="products__container">
                        {/*TODO consider adding an h1 title for this page*/}

                        {/*Check the double search thing*/}
                        <div className="products__search">
                            <input type="text"
                                   placeholder="Search"
                                   value={search}
                                   onChange={e => {
                                       setSearch(e.target.value.toLowerCase())
                                       e.target.value.toLowerCase() !== BLANK ? setSelectedCategory(BLANK) : setSelectedCategory("All")
                                   }}
                            />
                        </div>
                        <div className="products__categories">
                            {
                                CATEGORIES.map((category: string, index: number) => {
                                    return (
                                        <div
                                            key={index}
                                            className={
                                                category === selectedCategory ?
                                                    "products__categories-selected" :
                                                    "products__category-option"
                                            }
                                            onClick={(event) => {
                                                event.preventDefault()
                                                setSelectedCategory(category)
                                                setSearch(BLANK)
                                                navigate(Directory.MENU)
                                            }}
                                        >
                                            {category}
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className="products__cards">
                            {
                                filteredProducts().length === 0 ?
                                    <div className="menu__no-products">
                                        No products were found matching your selection.
                                    </div> :
                                    filteredProducts().map((product: Product) => {
                                        return (
                                            <div
                                                key={product.id}
                                                className="products__card-item"
                                            >
                                                <Link
                                                    to={`${Directory.MENU}/${product.name}`}
                                                    state={product}
                                                >
                                                    {
                                                        product.primaryImage ?
                                                            <img
                                                                className="products__card-image"
                                                                src={BUCKET_ASSETS_LINK + product.primaryImage}
                                                                alt={product.name}
                                                            /> :
                                                            <div className="products__card-image-unavailable">
                                                                Image Currently Unavailable
                                                            </div>
                                                    }
                                                    <div className="products__card-name">
                                                        {product.name}
                                                    </div>
                                                    {
                                                        product.prices.length > 0 ?

                                                            <div key={product.id} className="products__card-price">
                                                                {
                                                                    product.prices.length > 1 ?
                                                                        `From ${findLowestPrice(product.prices).cost} AED` :
                                                                        `${product.prices[0].cost} AED`
                                                                }
                                                            </div> :
                                                            <div>
                                                                No prices listed for this product yet
                                                            </div>
                                                    }
                                                </Link>
                                            </div>
                                        )
                                    })
                            }
                        </div>
                    </div>
            }
        </>
    )
}

export default Menu
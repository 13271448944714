import {Outlet} from "react-router-dom"
import {useEffect, useState} from "react"

import useAuth from "hooks/useAuth"
import {useLocalStorage} from "usehooks-ts"
import useRefreshToken from "hooks/useRefreshToken"

const PersistLogin = () => {
    const {auth} = useAuth()
    const refresh = useRefreshToken()
    const [persist] = useLocalStorage("persist", false)
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        let isMounted = true

        const verifyRefreshToken = async () => {
            try { await refresh() }
            catch (err) { console.log(err) }
            finally { isMounted && setIsLoading(false) }
        }
        !auth?.accessToken ? verifyRefreshToken() : setIsLoading(false)

        return () => {
            isMounted = false
        }
    }, [auth?.accessToken, refresh])

    return <> {!persist ? <Outlet/> : isLoading ? <p>Loading...</p> : <Outlet/>} </>
}

export default PersistLogin
import axios from "axios"

import useAuth from "hooks/useAuth"

const useSignOut = () => {
    const {setAuth} = useAuth()

    return async () => {
        setAuth({})
        localStorage.clear()

        await axios.post("/sign-out", {}, {withCredentials: true}).catch(console.error)
    }
}

export default useSignOut
import React from "react"
import ReactDOM from "react-dom/client"
import {HelmetProvider} from "react-helmet-async"
import {HashRouter as Router, Route, Routes} from "react-router-dom"

import App from "./App"
import {AuthProvider} from "security/AuthProvider"

import "./index.css"
import "./styles/buttons.css"

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
)
root.render(
    <React.StrictMode>
        <Router>
            <AuthProvider>
                <Routes>
                    <Route path="/*" element={
                        <HelmetProvider>
                            <App/>
                        </HelmetProvider>
                    }/>
                </Routes>
            </AuthProvider>
        </Router>
    </React.StrictMode>
)

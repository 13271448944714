import axios from "axios"
import React, {useState} from "react"
import {useLocation, useNavigate} from "react-router-dom"

import useAuth from "hooks/useAuth"
import useToggle from "hooks/useToggle"
import Directory from "utilities/Directory"

import "./sign-in.css"

const SignIn = () => {

    const {setAuth} = useAuth()
    const navigate = useNavigate()
    const location: any = useLocation()
    const from = location.state?.from?.pathname || Directory.ADMIN_DASHBOARD

    const [check, toggleCheck] = useToggle("persist", false)

    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [response, setResponse] = useState("")

    const handleSignIn = async (event: React.FormEvent) => {
        event.preventDefault()

        axios.post("/sign-in", {"username": username.toLowerCase(), "password": password})
            .then(response => {

                const user = response?.data?.user
                const accessToken = response?.data?.accessToken

                setAuth({user, accessToken})

                navigate(from, {replace: true})
            })
            .catch(() => setResponse("Login failed, please try again"))
    }

    return (
        <div className="sign-in__page">
            <div className="sign-in__box">
                <h2>Admin Panel</h2>
                <form className="sign-in__form" onSubmit={handleSignIn}>
                    <input
                        placeholder="Username"
                        value={username}
                        onChange={event => setUsername(event.target.value)}
                        required
                    />
                    <input
                        placeholder="Password"
                        value={password}
                        type="password"
                        onChange={event => setPassword(event.target.value)}
                        required
                    />
                    <div className="sign-in__remember">
                        <input
                            type="checkbox"
                            id="persist"
                            onChange={toggleCheck}
                            checked={check}
                        />
                        <label htmlFor="persist">Remember me on this device</label>
                    </div>
                    <div className="sign-in__response">
                        {response}
                    </div>
                    <button type="submit" className="form-btn">
                        Sign In
                    </button>
                </form>
            </div>
        </div>
    )
}

export default SignIn
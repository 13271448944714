enum Categories {
    ALL = "All",

    CAKE = "Cake",

    BROWNIES = "Brownies",

    COOKIES = "Cookies",

    PUDDING = "Pudding",

    TRUFFLES = "Truffles",

    COFFEE = "Coffee",

    MISCELLANEOUS = "Miscellaneous"
}

export default Categories

// This doesn't include all because it only includes the selectable categories
export const categories = [
    {value: Categories.CAKE, label: Categories.CAKE},
    {value: Categories.BROWNIES, label: Categories.BROWNIES},
    {value: Categories.COOKIES, label: Categories.COOKIES},
    {value: Categories.PUDDING, label: Categories.PUDDING},
    {value: Categories.TRUFFLES, label: Categories.TRUFFLES},
    {value: Categories.COFFEE, label: Categories.COFFEE},
    {value: Categories.MISCELLANEOUS, label: Categories.MISCELLANEOUS}
]
import Dropzone, {Accept} from "react-dropzone"

import "./image-upload.css"

const acceptedFileTypes: Accept = {
    "image/png": [".png"],
    "image/jpeg": [".jpg", ".jpeg"]
}

const ImageUpload = (props: any) => {

    const setResponse = props.setResponse
    const setUploadedFiles = props.setUploadedFiles

    const handleFilesDrop = (acceptedFiles: File[]) => {
        let rejectedFiles: string[] = []
        acceptedFiles.forEach(file => {
            if (file.size < 10000000) setUploadedFiles((prevState: File[]) => [...prevState, file])
            else rejectedFiles.push(file.name)
        })

        if (rejectedFiles.length !== 0) {
            const message =
                <>
                    Image size is too big for the file(s): <br/>
                    {
                        rejectedFiles.map((file) => {
                            return (
                                <>
                                    {file}
                                    <br/>
                                </>
                            )
                        })
                    }
                </>
            setResponse(message)
        }
    }

    return (
        <Dropzone
            accept={acceptedFileTypes}
            onDrop={acceptedFiles => handleFilesDrop(acceptedFiles)}
            multiple={true}
        >
            {({getRootProps, getInputProps}) => (
                <section>
                    <div {...getRootProps()} className="upload__container">
                        <input {...getInputProps()} />
                        <p>
                            Drag and drop some files here, or click to select files <br/>
                            Max file size is 10 MB
                        </p>
                    </div>
                </section>
            )}
        </Dropzone>
    )
}

export default ImageUpload
import React from "react"

const Unauthorized = () => {
    return (
        <div className="page__height">
            Unauthorized Access, Go Away
        </div>
    )
}

export default Unauthorized
import {OnChangeValue} from "react-select"

import Roles from "./Roles"
import {BLANK} from "./Constants"
import Allergens from "utilities/Allergens"
import Categories from "utilities/Categories"

export interface Product {
    "id": number
    "name": string
    "prices": Price[]
    "description": string | null
    "category": Categories | null
    "allergens": Allergens[]
    "primaryImage": string | null
    "productLinks": string[]
}

export interface ProductStruct {
    "id": number
    "name": string
    "prices": Price[]
    "description": string | null
    "category": OnChangeValue<any, any>
    "allergens": any /*If I put any[] it gives an immutable warning*/
    "primaryImage": string | null
    "productLinks": string[]
}

export const defaultProductStruct = (): ProductStruct => {
    return {
        id: -1,
        name: BLANK,
        description: BLANK,
        allergens: [],
        category: null,
        prices: [defaultPrice()],
        primaryImage: null,
        productLinks: []
    }
}

export interface Price {
    "id": number
    "cost": number
    "servingSize": string | null
}

export const defaultPrice = (): Price => {
    return {
        id: 0,
        cost: 0,
        servingSize: null
    }
}

export interface Featured {
    "id": number
    "product": number
    "priority": number
}

export interface UserStruct {
    username: string
    password: string
    email: string
    role: OnChangeValue<any, any>
}

export interface User {
    username: string
    password: string
    email: string
    roles: Roles[]
}

export const defaultUserStruct = (): UserStruct => {
    return {
        username: BLANK,
        password: BLANK,
        email: BLANK,
        role: Roles.EMPLOYEE
    }
}
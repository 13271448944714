import React from "react"
import {BsCart4} from "react-icons/bs"
import {FiMapPin} from "react-icons/fi"
import {Blobs, Spoon} from "assets/assets"
import Directory from "utilities/Directory"
import {useNavigate} from "react-router-dom"
import {TbTruckDelivery} from "react-icons/tb"

const Welcome = () => {
    const navigate = useNavigate()

    return (
        <div className="home__container">
            <Blobs/>
            <div className="home__greetings">
                <div className="home__left">
                    <h1>Sharing Sweet Memories<br/>Since 2019</h1>
                    <h2>We deliver your favourite desserts and coffees all over the UAE.</h2>
                    <button className="trademark-btn home__menu-btn" onClick={() => navigate(Directory.MENU)}>
                        See Menu
                    </button>
                </div>
                <Spoon/>
                <div className="home__icons">
                    <div>
                        Pick your favorite<br/>Semisweet dessert
                        <BsCart4 size={38}/>
                    </div>
                    <div>
                        Select your location<br/>or pick up your order
                        <FiMapPin size={38}/>
                    </div>
                    <div>
                        Order arrives<br/>within 2 hours
                        <TbTruckDelivery size={38}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Welcome
enum Allergens {
    DAIRY = "Dairy",
    NUTS = "Nuts",
    PEANUTS = "Peanuts",
    GLUTEN = "Gluten",
    EGG = "Egg",
    SESAME = "Sesame",
    SULPHITES = "Sulphites",
    SOY = "Soy"
}

export default Allergens

export const allergenList = [
    {value: Allergens.DAIRY, label: Allergens.DAIRY},
    {value: Allergens.NUTS, label: Allergens.NUTS},
    {value: Allergens.PEANUTS, label: Allergens.PEANUTS},
    {value: Allergens.GLUTEN, label: Allergens.GLUTEN},
    {value: Allergens.EGG, label: Allergens.EGG},
    {value: Allergens.SESAME, label: Allergens.SESAME},
    {value: Allergens.SULPHITES, label: Allergens.SULPHITES},
    {value: Allergens.SOY, label: Allergens.SOY}
]
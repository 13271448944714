import "./loading.css"

const Loading = () => {
    return (
        <div className="loading__container">
            <div className="loading"/>
            <div className="loading__text">loading</div>
        </div>
    )
}

export default Loading
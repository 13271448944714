import axios from "axios"
import {Link} from "react-router-dom"
import {Navigation, Pagination} from "swiper"
import {Swiper, SwiperSlide} from "swiper/react"
import React, {useEffect, useState} from "react"

import Directory from "utilities/Directory"
import {Product} from "utilities/Interfaces"
import {findLowestPrice} from "utilities/Functions"
import {BUCKET_ASSETS_LINK} from "utilities/Constants"

import "./featured-products.css"

const FeaturedProducts = () => {

    const [featuredProducts, setFeaturedProducts] = useState<Product[]>([])

    useEffect(() => {

        axios.get("api/v1/products/get-featured")
            .then(response => setFeaturedProducts(response.data))

    }, [])

    return (
        <div className="fp__container">
            <div className="fp__section">
                <div className="fp__section-header">
                    <h2>
                        Our Customer Favourites
                    </h2>
                </div>

                <Swiper
                    slidesPerView={1}
                    spaceBetween={20}
                    breakpoints={{
                        600: {
                            slidesPerView: 2,
                            spaceBetween: 40
                        },
                        1024: {
                            slidesPerView: 3,
                            spaceBetween: 40
                        }
                    }}
                    pagination={{clickable: true}}
                    navigation={true}
                    modules={[Navigation, Pagination]}
                    className="fp__swiper"
                >
                    {
                        featuredProducts.map((product: Product) => {
                            return (
                                <SwiperSlide key={product.id} className="fp__card">
                                    <Link
                                        to={`${Directory.MENU}/${product.name}`}
                                        state={product}
                                        className="fp__link"
                                    >
                                        <div className="fp__item">
                                            <img
                                                className="fp__image"
                                                src={BUCKET_ASSETS_LINK + product.primaryImage}
                                                alt={product.name}
                                            />
                                            <div className="fp__details">
                                                {product.name}
                                                <br/>
                                                {
                                                    product.prices.length === 0
                                                        ? "No prices currently listed"
                                                        : product.prices.length === 1
                                                            ? `${product.prices[0].cost} AED`
                                                            : `From ${findLowestPrice(product.prices).cost} AED`
                                                }
                                                <br/>
                                                <button className="fp__button">
                                                    View More
                                                </button>
                                            </div>
                                        </div>
                                    </Link>
                                </SwiperSlide>
                            )
                        })
                    }
                </Swiper>
            </div>
        </div>
    )
}

export default FeaturedProducts

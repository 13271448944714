enum Roles {
    DEVELOPER = "ROLE_DEVELOPER",
    ADMIN = "ROLE_ADMIN",
    EMPLOYEE = "ROLE_EMPLOYEE"
}

export default Roles

export const rolesList = [
    {value: Roles.DEVELOPER, label: Roles.DEVELOPER},
    {value: Roles.ADMIN, label: Roles.ADMIN},
    {value: Roles.EMPLOYEE, label: Roles.EMPLOYEE}
]